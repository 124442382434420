import React from 'react'
import Helmet from 'react-helmet'

import Version from '../components/Version/Version'

const VersionIndex = ({ location }) => (
  <>
    <Helmet title={'Ovation | Version'}>
      <meta
        name="description"
        content="Practice your public speaking in VR. Ovation provides realistic virtual audiences, tools and feedback to help you grow your skills."
      />
      <meta name="keywords" content="VR, Public, Speaking, Training" />
    </Helmet>
    <Version goal={location.state?.goal || 'signin'} />
  </>
)

export default VersionIndex
