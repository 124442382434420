import React from 'react'

import './Version.scss'

const PC_VR_IMAGE = 'https://d1syj4d8txnu77.cloudfront.net/Version/HeadsetPluggingIn_Desktop.png'
const PC_VR_LAPTOP_IMAGE = 'https://d1syj4d8txnu77.cloudfront.net/Version/PCVRLaptop.png'
const VR_LAPTOP_IMAGE = 'https://d1syj4d8txnu77.cloudfront.net/Version/VRLaptop.png'
const MONITOR_IMAGE = 'https://d1syj4d8txnu77.cloudfront.net/Version/StandaloneHeadset.png'
const LAPTOP_IMAGE = 'https://d1syj4d8txnu77.cloudfront.net/Version/Laptop.png'

const Version = ({ goal }) => {
  return goal === 'signin' ? (
    <div className="version-wrapper container text-center my-5">
      <h3 className="mb-3">Which version of Ovation do you use?</h3>
      <div className="row justify-content-center align-items-stretch wrap-reverse">
        <div className="col-xs-12 col-md-4 content-column">
          <div className="image-wrapper">
            <a href={`https://portal.ovationvr.com/${goal}`}>
              <img src={PC_VR_LAPTOP_IMAGE} />
            </a>
          </div>
          <div>
            <h3 className="mt-4">Original</h3>
            <h4>PC-VR & Monitor</h4>
            <a className="signup-button action-button" href={`https://portal.ovationvr.com/${goal}`}>
              SIGN IN
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-md-2 d-flex align-items-center justify-content-center">
          <h3 className="m-3">OR</h3>
        </div>
        <div className="col-xs-12 col-md-4 content-column">
          <div className="image-wrapper">
            <a href={`https://app.prod.ovationvr.com/${goal}`}>
              <img src={VR_LAPTOP_IMAGE} />
            </a>
          </div>
          <div>
            <h3 className="mt-4">New</h3>
            <h4>Meta Quest & Monitor</h4>
            <a className="signup-button action-button" href={`https://app.prod.ovationvr.com/${goal}`}>
              SIGN IN
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="version-wrapper container text-center my-5">
      <div className="row justify-content-center align-items-stretch">
        <div className="col-xs-12 col-md-4 content-column">
          <div className="image-wrapper">
            <a href={`https://app.prod.ovationvr.com/${goal}`}>
              <img className="small" src={MONITOR_IMAGE} />
            </a>
          </div>
          <div>
            <h3 className="mt-4">Wireless VR</h3>
            <h4>Meta Quest</h4>
          </div>
        </div>
        <div className="col-xs-12 col-md-1 d-flex align-items-center justify-content-center">
          <h3 className="m-3"></h3>
        </div>
        <div className="col-xs-12 col-md-4 content-column">
          <div className="image-wrapper">
            <a href={`https://app.prod.ovationvr.com/${goal}`}>
              <img className="small" src={LAPTOP_IMAGE} />
            </a>
          </div>
          <div>
            <h3 className="mt-4">Monitor</h3>
            <h4>Windows PC</h4>
          </div>
        </div>
      </div>
      <div className="py-3">
        <a className="signup-button action-button" href={`https://app.prod.ovationvr.com/${goal}`}>
          Sign Up
        </a>
      </div>
      <div className="pt-5">
        <h4 style={{ color: 'inherit' }}>
          Need to sign up for our original software for PC-connected VR headsets and Monitors?
        </h4>
        <a className="action-link" href={`https://portal.ovationvr.com/${goal}`}>
          Sign Up Here
        </a>
      </div>
    </div>
  )
}

export default Version
